import React from "react";
import { Link, easeOutCubic } from "react-scroll";

const NavBar = () => {
  const sections = ["home", "about", "projects", "contact"];
  const [menuOpen, setMenuOpen] = React.useState(false);
  const lineClass =
    "h-[3px] w-6 my-[3px] bg-white rounded-full transition ease-out";

  return (
    <div className="fixed left-0 top-0 z-50 h-16 w-full bg-black">
      <div className="mx-auto mt-2 flex max-w-7xl items-center justify-between">
        <div className="ml-3 flex cursor-pointer bg-gradient-to-bl from-green-400 to-blue-400 bg-clip-text text-4xl font-bold text-white transition ease-out hover:text-transparent">
          <Link
            to="home"
            smooth={"easeOutCubic"}
            duration={300}
            className="px-2 pb-1"
          >
            Tanzim
          </Link>
        </div>

        <div className="mr-6 hidden gap-4 sm:flex">
          {sections.map((section) => (
            <Link
              to={section}
              smooth={"easeOutCubic"}
              duration={500}
              className="cursor-pointer px-3 py-1 text-lg font-medium capitalize text-zinc-400 transition hover:text-white"
            >
              {section}
            </Link>
          ))}
        </div>

        <div
          className="mr-2 flex h-10 w-10 cursor-pointer flex-col items-center justify-center rounded sm:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div
            className={
              lineClass + (menuOpen && " translate-y-[9px] -rotate-45")
            }
          />
          <div className={lineClass + (menuOpen && " opacity-0")} />
          <div
            className={
              lineClass + (menuOpen && " -translate-y-[9px] rotate-45")
            }
          />
        </div>

        {menuOpen && (
          <div className="fixed left-0 top-16 flex h-[calc(100vh-64px)] w-full flex-col justify-center bg-gradient-to-b from-black to-zinc-800 text-zinc-400 sm:hidden">
            {sections.map((section) => (
              <Link
                to={section}
                smooth={"easeOutCubic"}
                duration={500}
                onClick={() => setMenuOpen(false)}
                className="w-full cursor-pointer py-4 text-center text-2xl font-medium capitalize hover:text-white"
              >
                {section}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
