import React from "react";
import heroImgFile from "../assets/Tanzim.jpeg";
import {
  FaLinkedin,
  FaSquareGithub,
  FaEnvelope,
  FaFilePdf,
} from "react-icons/fa6";

const Home = () => {
  return (
    <>
      <div className="h-16 w-full bg-black" id="home" />
      <div className="flex h-[calc(100vh-64px)] w-full items-center justify-center bg-gradient-to-b from-black to-zinc-800 px-4">
        <div className="flex max-w-7xl items-center justify-center">
          <div className="max-sm:hidden">
            <HeroImage />
          </div>
          <div className="max-w-7xl max-sm:flex max-sm:flex-col max-sm:items-center sm:pl-6 xl:pl-8">
            <h2 className="text-3xl text-white sm:text-4xl xl:text-5xl">
              Hi! I'm
            </h2>
            <div className="mt-4 sm:hidden">
              <HeroImage />
            </div>
            <h1 className="my-4 mb-6 text-4xl font-bold text-white sm:mb-9 sm:mt-2 sm:text-5xl xl:mb-12 xl:mt-3 xl:text-6xl">
              Tanzim Hossain
            </h1>
            <Socials />
          </div>
        </div>
      </div>
    </>
  );
};

const HeroImage = () => {
  return (
    <img
      className="w-48 rounded-xl sm:w-52 xl:w-64"
      src={heroImgFile}
      alt="Portrait of Tanzim"
    />
  );
};

const Socials = () => {
  const buttonClass =
    "relative flex h-12 w-12 items-center justify-center rounded-lg bg-gradient-to-b from-white to-zinc-200 transition-all ease-out hover:-translate-y-0.5 hover:shadow-sm max-sm:scale-90";
  return (
    <div className="flex gap-3 sm:gap-5">
      <a
        href="https://www.linkedin.com/in/tanzimfh/"
        target="_blank"
        className={buttonClass}
      >
        <FaLinkedin size={32} />
      </a>
      <a href="/resume" target="_blank" className={buttonClass}>
        <div className="ml-1.5">
          <FaFilePdf size={28} />
        </div>
      </a>
      <a
        href="https://github.com/tanzimfh"
        target="_blank"
        className={buttonClass}
      >
        <FaSquareGithub size={32} />
      </a>
      <a href="mailto:tanzimfh@gmail.com" className={buttonClass}>
        <FaEnvelope size={28} />
      </a>
    </div>
  );
};

export default Home;
