import React, { useState } from "react";
import { FaEnvelope, FaCopy, FaCheck } from "react-icons/fa6";
import { FaPaperPlane } from "react-icons/fa";

const Contact = () => {
  return (
    <div
      id="contact"
      className="flex h-min w-full items-center justify-center bg-gradient-to-b from-zinc-800 to-zinc-900 px-4 py-24"
    >
      <div className="text-white sm:max-w-xl xl:max-w-[45rem]">
        <h1 className="text-3xl font-bold sm:text-4xl xl:text-5xl">Contact</h1>
        <p className="my-4 text-lg sm:my-5 xl:my-6">
          I'm always open to new opportunities to connect and collaborate. Send
          me an email or fill out the form below to get in touch!
        </p>
        <div className="flex">
          <a
            href="mailto:tanzimfh@gmail.com"
            target="_blank"
            className="flex h-11 w-min items-center justify-center rounded-lg bg-gradient-to-b from-white to-zinc-200 px-3  transition-all ease-out hover:-translate-y-0.5 hover:shadow-sm"
          >
            <FaEnvelope size={24} color="black" />
            <div className="ml-2 text-lg font-medium text-black">Email</div>
          </a>
          <CopyButton />
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

const ContactForm = () => {
  const inputClass =
    "placeholder:text-zinc-500 rounded-md bg-zinc-300 px-3 py-2 text-black";

  return (
    <form
      id="contact-form"
      className="mt-12 flex max-w-md flex-col gap-4 text-white"
      action="https://getform.io/f/a7596726-c49b-4761-99d4-b7632697071f"
      method="POST"
    >
      <h2 className="text-xl font-medium sm:text-2xl xl:text-3xl">
        Contact Form
      </h2>
      <input
        type="text"
        name="name"
        placeholder="Name"
        className={inputClass}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        className={inputClass}
        required
      />
      <textarea
        name="message"
        placeholder="Message"
        className={inputClass}
        rows="4"
        required
      />
      <button className="flex h-11 w-min items-center justify-center rounded-lg bg-gradient-to-b from-white to-zinc-200 px-3 transition-all ease-out hover:-translate-y-0.5 hover:shadow-sm">
        <FaPaperPlane size={24} color="black" />
        <div className="ml-2 text-lg font-medium text-black">Submit</div>
      </button>
    </form>
  );
};

const CopyButton = () => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText("tanzimfh@gmail.com")
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        alert("Copy failed!");
      });
  };

  return (
    <button
      onClick={handleCopy}
      className={
        "ml-4 flex h-11 w-11 items-center justify-center rounded-lg bg-gradient-to-b pl-0.5 transition-all ease-out hover:-translate-y-0.5 hover:shadow-sm" +
        (copied ? " from-green-400 to-green-500" : " from-white to-zinc-200")
      }
    >
      {copied ? (
        <FaCheck size={24} color="black" />
      ) : (
        <FaCopy size={24} color="black" />
      )}
    </button>
  );
};

export default Contact;
