import React from "react";
import { FaFilePdf, FaLinkedin } from "react-icons/fa6";

const About = () => {
  return (
    <div
      id="about"
      className="flex h-min w-full items-center justify-center bg-zinc-800 px-4 py-24"
    >
      <div className="text-white sm:max-w-xl xl:max-w-[45rem]">
        <h1 className="text-3xl font-bold sm:text-4xl xl:text-5xl">About</h1>
        <p className="my-4 text-lg sm:my-5 xl:my-6">
          I'm an undergraduate student at the University of Windsor, currently
          completing my fourth year of a Bachelor of Science in Computer
          Science.
          <br />
          <br />
          Check out my LinkedIn or resume to learn more about what I've been up
          to!
        </p>
        <div className="mt-4 flex">
          <a
            href="https://www.linkedin.com/in/tanzimfh/"
            target="_blank"
            className="flex h-11 items-center justify-center rounded-lg bg-gradient-to-b from-white to-zinc-200  px-3 transition-all ease-out hover:-translate-y-0.5 hover:shadow-sm"
          >
            <FaLinkedin size={28} color="black" />
            <div className="ml-2 w-min text-lg font-medium text-black">
              LinkedIn
            </div>
          </a>
          <a
            href="/resume"
            target="_blank"
            className="ml-4 flex h-11 w-min items-center justify-center rounded-lg bg-gradient-to-b from-white to-zinc-200  px-3 transition-all ease-out hover:-translate-y-0.5 hover:shadow-sm"
          >
            <FaFilePdf size={24} color="black" />
            <div className="ml-2 text-lg font-medium text-black">Resume</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
