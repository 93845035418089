import React from "react";
import { FaSquareGithub } from "react-icons/fa6";

const Projects = () => {
  return (
    <div
      id="projects"
      className="flex h-min w-full items-center justify-center bg-zinc-800 px-4 py-24"
    >
      <div className="text-white sm:max-w-xl xl:max-w-[45rem]">
        <h1 className="text-3xl font-bold sm:text-4xl xl:text-5xl">Projects</h1>
        <p className="my-4 text-lg sm:my-5 xl:my-6">
          This section is still under construction. In the meantime, you can
          check out some of my projects on Github.
        </p>
        <a
          href="https://github.com/tanzimfh"
          target="_blank"
          className="flex h-11 w-min items-center justify-center rounded-lg bg-gradient-to-b from-white to-zinc-200  px-3 transition-all ease-out hover:-translate-y-0.5 hover:shadow-sm"
        >
          <FaSquareGithub size={28} color="black" />
          <div className="ml-2 text-lg font-medium text-black">Github</div>
        </a>
      </div>
    </div>
  );
};

export default Projects;
