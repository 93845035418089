import NavBar from "./components/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  if (window.location.pathname === "/resume") {
    window.location.href = "/Tanzim_Hossain_Resume.pdf";
    return null;
  }

  if (window.location.pathname !== "/") {
    window.location.href = "/";
    return null;
  }

  return (
    <div>
      <NavBar />
      <Home />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
