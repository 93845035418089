import React from "react";

const Footer = () => {
  return (
    <div className="flex h-min w-full items-center justify-center bg-gradient-to-b from-zinc-900 to-black pb-20 pt-24">
      <div className="flex flex-col items-center gap-1 text-center text-zinc-400">
        <p>Made with React</p>
        <p>© 2024 Tanzim Hossain</p>
      </div>
    </div>
  );
};

export default Footer;
